import React, { ReactElement, useEffect, useState } from "react";
import { ProfileMeResponse } from "../../models/core/profile";
import { getProfile } from "../../services/core/ProfileService";
import { createNewAccount } from "../../services/strapi/AccountService";
import { useSession } from "next-auth/react";

interface UserRoutesProps {
    children: ReactElement;
}

export const userContext = React.createContext<ProfileMeResponse | undefined>(undefined);

export const UserProvider: React.FC<UserRoutesProps> = ({ children }) => {
    const [data, setData] = useState<ProfileMeResponse>();

    const { data: session } = useSession();

    useEffect(() => {
        if (session) {
            getProfile().then((r: ProfileMeResponse) => {
                if (r) {
                    createNewAccount(r.uuid).then((res) => {
                        r.strapi_account_id = res.data.id;
                        setData(r);
                    });
                }
            });
        }
    }, [session]);

    const { Provider } = userContext;
    return <Provider value={data}>{children}</Provider>;
};
