import {getCookie, setCookie} from "../utils/cookies/cookies";
import {cookieNames} from "../utils/constants";

export const setCookieConsent = (value: boolean) => {
    return setCookie(cookieNames.ACCEPTED_COOKIES, String(value))
};

export const getCookieConsent = () => {
    return getCookie(cookieNames.ACCEPTED_COOKIES) === String(true);
};
