import React, { ReactElement, useEffect, useState } from "react";
import { useWindowSize } from "../window";

interface UserRoutesProps {
    children: ReactElement;
}

interface SizeProps {
    height: number;
    width: number;
}

interface ScreenSizeProps extends SizeProps {
    screenXS: boolean;
    screenS: boolean;
    screenSM: boolean;
    screenM: boolean;
}

export const screenBrakePoints = {
    screenXS: 750,
    screenS: 830,
    screenSM: 1023,
    screenM: 1024,
};

export const screenSizeContext = React.createContext<ScreenSizeProps>({
    height: 0,
    width: 0,
    screenXS: false,
    screenS: false,
    screenSM: false,
    screenM: false,
});

export const ScreenSizeProvider: React.FC<UserRoutesProps> = ({ children }) => {
    const size = useWindowSize();
    const [currentSize, setCurrentSize] = useState<ScreenSizeProps>();

    useEffect(() => {
        const currentSize = {
            width: size.width,
            height: size.height,
            screenXS: size.width < screenBrakePoints.screenXS,
            screenS: size.width < screenBrakePoints.screenS,
            screenSM: size.width < screenBrakePoints.screenSM,
            screenM: size.width > screenBrakePoints.screenM,
        };
        setCurrentSize(currentSize);
    }, [size]);

    // @ts-ignore
    return <screenSizeContext.Provider value={currentSize}>{children}</screenSizeContext.Provider>;
};
