import { StrapiAccountCreateResponse } from "../../models/strapi/strapi";
import { prepareStrapiUrl } from "../../utils/core/coreUtils";
import { doApiCall } from "../../api/api";

const ACCOUNTS = "/accounts";

export const getAccountByUuid = (accountUuid: string) => {
    const url = ACCOUNTS;
    const query = {
        filters: {
            uuid: {
                $eq: accountUuid,
            },
        },
    };

    const builtQuery = prepareStrapiUrl(`${url}?${query}`);
    const options = {
        url: builtQuery,
        method: "GET",
    };

    return doApiCall(options);
};

export const createNewAccount = (accountUuid: string): Promise<StrapiAccountCreateResponse> => {
    const url = `${ACCOUNTS}/createIfDoesntExist`;
    const body = {
        data: {
            uuid: accountUuid,
        },
    };

    const builtQuery = prepareStrapiUrl(`${url}`);
    const options = {
        url: builtQuery,
        method: "POST",
        data: body,
    };

    return doApiCall(options);
};
