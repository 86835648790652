import { useSession } from "next-auth/react";
import { NextRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";
import { Spinner } from "../../design/Layouts/states/loading/Spinner";
import { UserProvider } from "../context/UserProvider";
import { CookieRoutes } from "./CookieRoutes";

interface ProtectedRoutesProps {
    children: ReactElement;
    router: NextRouter;
    isPagePublic: boolean | undefined;
}

// const isBrowser = (): boolean | string => typeof window !== "undefined";
/**
 *
 * This function checks if the current user is logged in, and if not it checks
 * if the user is on a public or protected page
 * On a public page the user can stay, but on a protected page they will get redirected
 * to the login page
 */
export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ children, router, isPagePublic }) => {
    const { data: session, status } = useSession();
    const loading = status === "loading";

    const loginRoute = "/user/login";

    useEffect(() => {
        const ignoredRoutes = [loginRoute, "/user/signout", "/404", "/500"];

        if (!session && !loading && typeof isPagePublic !== "boolean" && !isPagePublic) {
            if (!ignoredRoutes.includes(router.pathname)) {
                router.push({ pathname: loginRoute, query: { next: router.asPath } }).then((p) => p);
            } else {
                router.push({ pathname: loginRoute }).then((p) => p);
            }
        }
    }, [isPagePublic, loading, router, session]);

    // When there is a browser and the user is loading return nothing
    if (typeof window !== "undefined" && loading) return null;

    if (!session) {
        if (isPagePublic === true) return children;

        return <Spinner />;
    }

    return (
        <CookieRoutes isPagePublic={isPagePublic === true} router={router}>
            <UserProvider>{children}</UserProvider>
        </CookieRoutes>
    );
};
