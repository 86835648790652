import { environmentNames } from "../constants";
import * as Sentry from "@sentry/nextjs";

/**
 * ###########################
 * ########## USAGE ##########
 * ###########################
 *
 * ########## ON A PAGE ######
 * You can call this function, with the required environment, and page parameter set to true
 *
 * checkEnvironment(STAGING, true)
 * This will return {notFound: true} if you are in Stable or Production.
 *
 * ###### IN A COMPONENT #####
 * If you want to cross check if the component should be available in a certain environment,
 * simply call this function with the requested env, and it will return true or false.

 */
const envLevel = (env: string): number => {
    if (env.toLowerCase() === environmentNames.PRODUCTION.toLowerCase()) return 3;
    if (env.toLowerCase() === environmentNames.STABLE.toLowerCase()) return 2;
    if (env.toLowerCase() === environmentNames.STAGING.toLowerCase()) return 1;
    if (env.toLowerCase() === environmentNames.DEV.toLowerCase()) return 0;

    Sentry.captureMessage(`envLevel not implemented for ${env}`);
    return 0;
};

export const hideComponent = (env: string): boolean => {
    return envLevel(getCurrentEnvironment()) <= envLevel(env);
};

export const devFeatures = (): boolean => {
    return process.env.NEXT_PUBLIC_DEV_FEATURES == "true";
};

export const isDev = (): boolean => {
    return getCurrentEnvironment() === environmentNames.DEV;
};

export const isStable = (): boolean => {
    return getCurrentEnvironment() === environmentNames.STABLE;
};

export const isStaging = (): boolean => {
    return getCurrentEnvironment() === environmentNames.STAGING;
};

export const isProd = (): boolean => {
    return getCurrentEnvironment() === environmentNames.PRODUCTION;
};

export const getCurrentEnvironment = (): string => {
    return process.env.NEXT_PUBLIC_ENVIRONMENT || "";
};

export const getCurrentNodeEnvironment = (): string => {
    return process.env.NODE_ENV;
};
