import {
    DeveloperCreationRequest,
    PasswordChangeInitializationRequest,
    ProfileDevelopersResponse,
    ProfileMeResponse,
    ProfileModificationRequest,
    ProfileOtpToggleRequest
} from "../../models/core/profile";
import { prepareCoreUrl } from "../../utils/core/coreUtils";
import { apiPaths } from "../../utils/constants";
import { doApiCall } from "../../api/api";
import { delCache } from "../../utils/cache/cacheUtils";

export const getProfile = (): Promise<ProfileMeResponse> => {
    const builtUrl = prepareCoreUrl(apiPaths.PROFILE_ME);

    const cacheForMilliseconds = 3540000; // 59 minutes

    const options = {
        url: builtUrl,
        method: "GET"
    };
    return doApiCall(options, true, cacheForMilliseconds);
};

export const saveDeveloper = (request: DeveloperCreationRequest) => {
    const url = apiPaths.PROFILE_DEVELOPERS;
    const builtQuery = prepareCoreUrl(url);
    const options = {
        url: builtQuery,
        method: "POST",
        data: { request }
    };

    return doApiCall(options, false, 0, false, false);
};

export const getDevelopers = (): Promise<ProfileDevelopersResponse> => {
    const builtUrl = prepareCoreUrl(apiPaths.PROFILE_DEVELOPERS);

    const options = {
        url: builtUrl,
        method: "GET"
    };
    return doApiCall(options);
};

export const saveProfile = (request: ProfileModificationRequest): Promise<ProfileMeResponse> => {
    const url = apiPaths.PROFILE_ME;
    const builtQuery = prepareCoreUrl(url);
    const options = {
        url: builtQuery,
        method: "PUT",
        data: { request }
    };

    return doApiCall(options);
};

export const removeDeveloper = (developerUuid: string) => {
    const url = `${apiPaths.PROFILE_DEVELOPERS}/${developerUuid}`;
    const builtQuery = prepareCoreUrl(url);
    const options = {
        url: builtQuery,
        method: "DELETE"
    };

    delCache(prepareCoreUrl(apiPaths.PROFILE_DEVELOPERS));

    return doApiCall(options);
};

export const initializeOtpChange = (request: ProfileOtpToggleRequest) => {
    const url = `/accounts/me/otp/initiate/`;
    const data = {
        current_password: request.current_password,
        action_type: request.action_type
    };
    const builtQuery = prepareCoreUrl(url);
    const options = {
        url: builtQuery,
        method: "POST",
        data: data
    };

    return doApiCall(options, false, 0, false, false);
};

export const confirmOtpChange = (changeUuid: string, secret: string, otp: string) => {
    const url = `/accounts/me/otp/${changeUuid}/confirm/`;
    const data = {
        secret: secret,
        otp: otp
    };
    const builtQuery = prepareCoreUrl(url);
    const options = {
        url: builtQuery,
        method: "POST",
        data: data
    };

    delCache(prepareCoreUrl(apiPaths.PROFILE_ME))

    return doApiCall(options, false, 0, false, false);
};

export const changePassword = (request: PasswordChangeInitializationRequest) => {
    const pwdChangeInitializationUrl = `/accounts/me/password-change/initiate/`;

    const builtQuery = prepareCoreUrl(pwdChangeInitializationUrl);
    const options = {
        url: builtQuery,
        method: "POST",
        data: { request }
    };

    return doApiCall(options).then((r) => {
        const passwordChangeConfirmationUrl = `/accounts/me/password-change/${r.data.password_change_uuid}/confirm/`;
        const data = {
            new_password: request.new_password,
            otp: request.otp,
            secret: r.data.secret
        };

        const builtQuery = prepareCoreUrl(passwordChangeConfirmationUrl);
        const options = {
            url: builtQuery,
            method: "POST",
            data: data
        };
        return doApiCall(options);
    });
};
