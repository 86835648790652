import axios from "axios";
import { delCache, getCache, setCache } from "../utils/cache/cacheUtils";

export const doApiCall = (
    options: { url: string; method: string; data?: object; headers?: object },
    allowCache: boolean = true,
    cacheForMilliseconds?: number,
    refreshAfter: boolean = false,
    redirectOnError: boolean = true
): Promise<any> => {
    const handleAxiosError = (redirectOnError: boolean, error: any) => {
        if (redirectOnError) {
            if (error.response) {
                const statusCode = error.response.status;

                let href = null;

                if (statusCode === 401) {
                    const nextSplit = window.location.href.split("next=");
                    if (nextSplit.length > 1) {
                        href = `/user/signout?next=${nextSplit[1]}`;
                    } else if (window.location.pathname !== "/user/signout") {
                        href = `/user/signout?next=${window.location.pathname}`;
                    } else if (nextSplit.length === 1 || window.location.pathname === "/user/signout") {
                        href = `/user/signout`;
                    }
                } else if (statusCode === 403) href = "/403";
                else if (statusCode === 404) href = "/404";

                if (!href) href = "/500";

                if (href && !window.location.href.endsWith(href))
                    // window.location.href = `${href}?backUrl=${window.location.href}`;
                    window.location.href = href;
            }
        } else {
            throw error;
        }
    };

    allowCache = options.method.toLowerCase() === "get" && allowCache && process.env.NEXT_PUBLIC_DEV_FEATURES == "true";

    if (allowCache) {
        const cachedResponse = getCache(options.url);
        if (cachedResponse) {
            if (refreshAfter) {
                axios(options)
                    .then((r) => {
                        if (Array.isArray(r.data.data) || !Array.isArray(r.data.data))
                            setCache(options.url, r.data, cacheForMilliseconds);
                    })
                    .catch((error) => {
                        handleAxiosError(redirectOnError, error);
                    });
            }
            return Promise.resolve(cachedResponse);
        }
    }

    if (options.method.toLowerCase() !== "get") {
        delCache(options.url);
    }

    return axios(options)
        .then((r) => {
            if (allowCache)
                if (Array.isArray(r.data.data) || !Array.isArray(r.data.data))
                    setCache(options.url, r.data, cacheForMilliseconds);

            return r.data;
        })
        .catch((error) => {
            handleAxiosError(redirectOnError, error);
        });
};
