
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { NextPageContext } from "next";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { AppTreeType } from "next/dist/shared/lib/utils";
import Head from "next/head";
import { NextRouter, Router } from "next/router";
import "nextjs-breadcrumbs/dist/index.css";
import "../utils/styles/globals.css";
import "../utils/styles/codemirror.css";
import { ProtectedRoutes } from "../utils/wrappers/ProtectedRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NProgress from "nprogress";
import "../utils/styles/nprogress.css";
import React from "react";
import { ScreenSizeProvider } from "../utils/context/ScreenSizeProvider";
import { ThemeProvider } from "next-themes";
import { wrapper } from "../stores/store";

interface Components extends NextPageContext {
    /** If this is set to true, this will bypass the Login page */
    isPublic?: boolean;
}

export declare type AppContextType<R extends NextRouter = NextRouter> = {
    Component: Components;
    AppTree: AppTreeType;
    ctx: NextPageContext;
    router: R;
};

type AppPropsCustom = AppContextType & AppProps;

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const MyApp = ({ Component, pageProps, router }: AppPropsCustom) => {
    return (
        <>
            <Head>
                <link
                    href="https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap"
                    rel="stylesheet"
                />
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <title>Prosperity Connect</title>
            </Head>

            <SessionProvider refetchInterval={0}>
                <ThemeProvider attribute="class">
                    <ScreenSizeProvider>
                        <ProtectedRoutes router={router} isPagePublic={Component.isPublic}>
                            <>
                                <ToastContainer />
                                {/*@ts-ignore*/}
                                <Component {...pageProps} />
                            </>
                        </ProtectedRoutes>
                    </ScreenSizeProvider>
                </ThemeProvider>
            </SessionProvider>
        </>
    );
};
const __Page_Next_Translate__ = wrapper.withRedux(MyApp);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  