import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";

export interface AuthState {
    unreadAmount: number[];
}

const initialState: AuthState = {
    unreadAmount: []
};

export const announcementSlice = createSlice({
    name: "unreadAnnouncementAmount",
    initialState,
    reducers: {
        setUnreadAmountRedux(state, action) {
            state.unreadAmount = action.payload;
        },
        // setUnreads(state, action) {
        //     state.unread = action.payload;
        // }
    }
});

export const { setUnreadAmountRedux } = announcementSlice.actions;

export const selectUnreadState = (state: AppState) => state.unreadAnnouncementAmount.unreadAmount;

export default announcementSlice.reducer;
