import React from "react";
import { LoadingSpinner } from "../../../misc/Icons";

/** Just a loading spinner, colours cannot be changed, should they? */
export const Spinner: React.FC = () => {
    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <LoadingSpinner className="text-lla-copper-500 h-20 w-20" />
        </div>
    );
};
