export const prepareCoreUrl = (url: string): string => {
    if (!url.startsWith("/")) url = `/${url}`;

    const baseUrl = "/api/core/api/";
    return `${baseUrl}?url=${encodeURIComponent(url)}`;
};

export const prepareStrapiUrl = (url: string): string => {
    if (!url.startsWith("/")) url = `/${url}`;

    const baseUrl = "/api/strapi/api/";
    return `${baseUrl}?url=${encodeURIComponent(url)}`;
};

export const prepareJiraUrl = (url: string): string => {
    if (!url.startsWith("/")) url = `/${url}`;

    const baseUrl = "/api/jira/request/";
    return `${baseUrl}?url=${encodeURIComponent(url)}`;
};
