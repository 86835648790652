/*
 * https://www.npmjs.com/package/memory-cache
 */

export const getCache = (key: string) => {
    const cache = require("memory-cache");
    if (cache)
        return cache.get(key);
    return null
};

export const setCache = (key: string, data: any, milliseconds?: number) => {
    const cache = require("memory-cache");

    if (milliseconds === undefined) {
        const hours = 24;
        milliseconds = hours * 1000 * 60 * 60;
    }

    // If milliseconds isn't passed in, it is stored forever
    return cache.put(key, data, milliseconds);
};

export const delCache = (key: string) => {
    const cache = require("memory-cache");
    cache.del(key);
};

export const clearCache = () => {
    const cache = require("memory-cache");
    cache.clear();
    console.log("cleared cache");
};