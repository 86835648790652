import { NextRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";
import { getCookieConsent } from "../../services/CookieConsentService";
import { Spinner } from "../../design/Layouts/states/loading/Spinner";

interface CookieRoutesProps {
    isPagePublic: boolean;
    children: ReactElement;
    router: NextRouter;
}

export const CookieRoutes: React.FC<CookieRoutesProps> = ({ children, isPagePublic, router }) => {
    const acceptedCookies = getCookieConsent();

    const cookieRoute = "/user/cookies";

    useEffect(() => {
        const ignoredRoutes = [cookieRoute, "/user/signout", "/404", "/500"];
        if (!isPagePublic && !acceptedCookies) {
            if (!ignoredRoutes.includes(router.pathname)) {
                router.push({ pathname: cookieRoute, query: { next: router.asPath } }).then((p) => p);
            } else {
                router.push({ pathname: cookieRoute }).then((p) => p);
            }
        }
    }, [acceptedCookies, isPagePublic, router]);

    if (!acceptedCookies && router.pathname !== cookieRoute && !isPagePublic) return <Spinner />;

    return <>{children}</>;
};
