export const cookieNames = {
    ACCEPTED_COOKIES: "connect-frontend-cookiesAccepted",
    THEME: "connect-frontend-theme"
}

export const environmentNames = {
    DEV: "DEV",
    STAGING: "STAGING",
    STABLE: "STABLE",
    PRODUCTION: "PRODUCTION",
}

export const userRoles = {
    SUPERUSER: "superuser",
    STAFF: "staff",
    DEV: "developer"
}

export const apiPaths = {
    ACTIVITY_PROTOCOL: "/api/core/activity-protocol",
    PROFILE_ME : "/frontend/v1/me",
    TARIFFS : "/frontend/v1/tariffs",
    PERMISSIONS : "/frontend/v1/permissions",
    LIFEWARE_BROKERS : `/frontend/v1/brokers/lla`,
    CASHYOU_BROKERS : `/frontend/v1/brokers/cashyou`,
    CASHYOU_platforms : `/frontend/v1/brokers/cashyou/platforms`,
    SERVICE_TYPES : "/frontend/v1/service-types",
    PROFILE_DEVELOPERS : "/frontend/v1/me/account/developers",
    ONE_TIME_TOKEN : "/api/v1/one-time-token",
    ADMIN_USERS: "/frontend/v1/users",
}

export const serviceTypes = {
    REST: "rest",
    BIPRO: "bipro"
}
