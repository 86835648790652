import Cookies from "js-cookie";
import {isDev} from "../environmentTools/checkEnv";

export const setCookie = (name: string, value: string, path = '/') => {
    return Cookies.set(name, value, {
        expires: 365,
        path: path,
        sameSite: "strict",
        secure: !isDev(),
    });
};

export const getCookie = (name: string) => {
    return Cookies.get(name)
}

export const deleteCookie = (name: string, path = '/') => {
    return Cookies.remove(name, {path: path})
}
